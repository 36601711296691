<template>
  <div class="container-fluid bg-gray-lighter">
    <div class="md:w-1/2 pt-8 pb-64 px-4 md:px-12">
      <breadcrumbs
        :path="[
          { name: $t('edit-admin-view.employees'), url: { name: 'Admins' } },
          { name: $t('edit-admin-view.edit-employee') },
        ]"
        class="mt-2 mb-1"
      ></breadcrumbs>
      <admin-form
        id="adminForm"
        :is-loading="adminIsLoading"
        :content="content"
        @on-submit="handleSubmission"
      ></admin-form>
    </div>
    <footer-menu>
      <div class="flex justify-between">
        <div>
          <btn
            :is-loading="adminIsLoading || submissionInProgress"
            form="adminForm"
            type="submit"
            class="w-30 md:w-48 mr-3"
          >
            {{ $t('common.save') }}
          </btn>
        </div>
        <btn theme="none" @click="$router.go(-1)">
          {{ $t('common.cancel') }}
        </btn>
      </div>
    </footer-menu>
  </div>
</template>

<script>
import Breadcrumbs from '@/components/Breadcrumbs/Breadcrumbs.vue';
import Btn from '@/components/Button/Button.vue';
import FooterMenu from '@/components/Footer/FooterMenu.vue';
import AdminForm from '@/views/Dashboard/Admins/Forms/AdminForm.vue';
import { mapActions } from 'vuex';

export default {
  name: 'EditAdmin',
  components: {
    Breadcrumbs,
    Btn,
    FooterMenu,
    AdminForm,
  },
  props: {
    id: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      content: null,
      submissionInProgress: false,
      adminIsLoading: false,
    };
  },
  async mounted() {
    this.adminIsLoading = true;
    try {
      this.content = await this.getAdmin(this.id);
    } finally {
      this.adminIsLoading = false;
    }
  },
  methods: {
    ...mapActions('admin', ['getAdmin', 'updateAdmin']),
    async handleSubmission(formData) {
      this.submissionInProgress = true;
      try {
        await this.updateAdmin({ id: this.id, data: formData });
        this.$toast.success(this.$t('edit-admin-view.updated-successfully'));
        this.$router.go(-1);
      } finally {
        this.submissionInProgress = false;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.container-fluid {
  @apply min-h-screen;
}
</style>
